import 'datatables.net/js/jquery.dataTables';
import 'datatables.net-responsive/js/dataTables.responsive';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4';
import 'datatables.net-buttons-bs4/js/buttons.bootstrap4';

$.extend($.fn.dataTable.ext.classes, {
  sWrapper: 'dataTables_wrapper dt-bootstrap4',
  sFilter: 'btn-toolbar-item input-with-icon',
  sFilterInput: 'form-control input-block',
});

$.extend(true, $.fn.dataTable.Buttons.defaults, {
  dom: {
    container: {
      className: 'btn-group d-flex justify-content-end flex-column flex-md-row',
    },
    button: {
      tag: 'a',
      className: 'btn',
    },
  },
});

(($) => {
  // eslint-disable-next-line func-names,no-param-reassign
  $.fn.defaultDataTable = function () {
    const paramsData = (table) => {
      return (params) => {
        const api = table.dataTable().api();

        api
          .columns()
          .flatten()
          .each((index) => {
            // eslint-disable-next-line no-param-reassign
            params.columns[index].visible = api.column(index).visible();
          });

        return params;
      };
    };

    const actionsColumn = (resourceName) => ({
      data: null,
      visible: true,
      defaultContent: '-',
      responsivePriority: 1,
      sortable: false,
      searchable: false,
      render(data) {
        const { showUrl } = data;
        const { editUrl } = data;
        const { destroyUrl } = data;

        const showBtn = `<a class="btn btn-success" href="${encodeURI(showUrl)}">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
            <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
          </svg>
        </a>`;

        const editBtn = `<a class="btn btn-primary" data-remote="true" href="${encodeURI(editUrl)}">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
          </svg>
        </a>`;

        const destroyBtn = `<a 
            class="btn btn-danger"
            data-confirm="Are you sure you want to delete this ${(
              resourceName || ''
            ).toLowerCase()}?"
            data-remote="true"
            data-method="delete"
            href="${encodeURI(destroyUrl)}"
          >
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </a>`;

        return `<div class="btn-group float-right">${showUrl ? showBtn : ''}${
          editUrl ? editBtn : ''
        }${destroyUrl ? destroyBtn : ''}</div>`;
      },
    });

    const dom =
      "<'row table-actions my-2'" +
      "<'col-md-5 mb-2'f>" +
      "<'col-md-7'B>" +
      '>' +
      "<'table-responsive'tr>" +
      "<'row table-actions my-2'" +
      "<'col-md-8'p>" +
      "<'col-md-4'i>" +
      '>';

    const exportCSVButton = (exportUrl) => {
      return {
        text: 'Export CSV',
        className: 'btn-outline-primary btn-export',
        action(_, dt) {
          const params = dt.settings()[0].ajax.data(dt.ajax.params());
          window.location = `${exportUrl}?${$.param(params)}`;
        },
      };
    };

    const colVisButton = {
      extend: 'colvis',
      className: 'btn-outline-primary',
      columns: ':not(:last-child)',
    };

    const newButton = (resourceName, href, text) => ({
      text:
        text ||
        `New ${_(resourceName || '')
          .snakeCase()
          .split('_')
          .join(' ')}`,
      className: 'btn-primary',
      attr: { href, 'data-remote': true },
      available() {
        return !!href;
      },
    });

    const getOptions = (table) => {
      const data = table.data();

      const columnsData = data.columnsData || [];
      const { resourceName, resourcesName, newUrl, newLabel, ajaxUrl, exportUrl } = data;

      return {
        ajax: {
          url: ajaxUrl,
          data: paramsData(table),
        },
        processing: false,
        serverSide: true,
        stateSave: true,
        autoWidth: false,
        responsive: {
          details: false,
        },
        order: [],
        pageLength: 20,
        language: {
          search: '',
          searchPlaceholder: `Search ${resourcesName || ''}...`,
          buttons: {
            colvis: 'Show/hide columns',
            excel: 'Download Excel',
          },
          paginate: {
            next:
              '<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>',
            previous:
              '<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/></svg>',
          },
        },
        columns: columnsData.concat([actionsColumn(resourceName)]),
        dom,
        buttons: [
          exportCSVButton(exportUrl),
          colVisButton,
          newButton(resourceName, newUrl, newLabel),
        ],
      };
    };

    return this.each((_, element) => {
      const $element = $(`#${element.id}`);

      if ($.fn.DataTable.fnIsDataTable($element)) {
        return;
      }

      const options = getOptions($element);

      $element.DataTable(options);

      $('.dataTables_wrapper')
        .find('.input-with-icon')
        .append(
          '<svg width="1em" height="1em" viewBox="0 0 16 16" class="icon bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/><path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/></svg>',
        );
    });
  };
})(jQuery);
