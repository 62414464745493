import { Controller } from '@hotwired/stimulus';

class SendOutController extends Controller {
  static targets = ['filter'];

  static values = {
    id: Number,
    customersUrl: String,
  };

  initialize() {
    this.customersPage = 1;
  }

  connect() {
    $(document).on('cocoon:after-remove', () => this.refreshCustomers());

    this.refreshCustomers();
  }

  disconnect() {
    $(document).off('cocoon:after-remove', () => this.refreshCustomers());
  }

  handleScroll(event) {
    const { offsetHeight, scrollHeight, scrollTop } = event.target;

    if (scrollHeight - scrollTop <= offsetHeight + 50) this.loadMoreCustomers();
  }

  refreshCustomers() {
    this.customersPage = 1;

    fetch(`${this.customersUrlValue}?${this.filterQueryParams}`)
      .then((response) => response.text())
      .then((html) => {
        this.element.querySelector('.filtered-customers').innerHTML = html;
      });
  }

  loadMoreCustomers() {
    this.customersPage += 1;

    fetch(`${this.customersUrlValue}?${this.filterQueryParams}`)
      .then((response) => response.text())
      .then((html) => {
        this.element.querySelector('.filtered-customers tbody').innerHTML =
          this.element.querySelector('.filtered-customers tbody').innerHTML + html;
      });
  }

  get filterQueryParams() {
    const defaultParams = ['send_out[filters_attributes][]', `page=${this.customersPage}`];

    if (this.idValue) {
      defaultParams.push(`send_out[id]=${this.idValue}`);
    }

    return `${this.filterTargets
      .filter((target) => target['send-out-filter'])
      .map((target) => target['send-out-filter'].filter)
      .reduce((params, filter) => {
        params.push(`send_out[filters_attributes][][filter_type]=${filter.filterType}`);
        params.push(`send_out[filters_attributes][][key]=${filter.key}`);

        filter.values
          .map((v) => `send_out[filters_attributes][][values][]=${v}`)
          .forEach((p) => params.push(p));

        return params;
      }, defaultParams)
      .join('&')}`;
  }
}

export default SendOutController;
