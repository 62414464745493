import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['row', 'count', 'all'];

  connect() {
    this.selected = new Set([]);

    this.rowTargets.forEach((target) => {
      if (target.checked) this._select(target);
    }, this);

    this._render();
  }

  toggleRow(event) {
    if (['button', 'a'].includes(event.target.tagName.toLowerCase())) {
      return;
    }

    this._toggle(event.currentTarget.querySelector("input[type='checkbox']"));
  }

  toggleCheckbox(event) {
    this._toggle(event.currentTarget);
  }

  toggleAll() {
    const action = this._noneSelected() ? 'select' : 'deselect';

    this.rowTargets.forEach((target) => {
      if (action === 'select') {
        this._select(target);
      } else {
        this._deselect(target);
      }
    }, this);

    this._render();
  }

  _toggle(checkbox) {
    if (!this.selected.has(checkbox.value)) {
      this._select(checkbox);
    } else {
      this._deselect(checkbox);
    }

    this._render();
  }

  _select(target) {
    // eslint-disable-next-line no-param-reassign
    target.checked = true;
    target.closest('tr').classList.add('table-success');
    this.selected.add(target.value);
  }

  _deselect(target) {
    // eslint-disable-next-line no-param-reassign
    target.checked = false;
    target.closest('tr').classList.remove('table-success');
    this.selected.delete(target.value);
  }

  _render() {
    if (this._allSelected()) {
      this.countTarget.textContent = 'All';
      this.allTarget.checked = true;
      this.allTarget.indeterminate = false;
    } else if (this._noneSelected()) {
      this.countTarget.textContent = 0;
      this.allTarget.checked = false;
      this.allTarget.indeterminate = false;
    } else {
      this.countTarget.textContent = this.selected.size;
      this.allTarget.indeterminate = true;
    }

    document.querySelectorAll('.bulk-actions a').forEach((element) => {
      const params = [...this.selected].reduce((str, id) => {
        const key = encodeURIComponent('sales_order_ids[]');
        const value = encodeURIComponent(id);
        return `${str}${key}=${value}&`;
      }, '');

      element.setAttribute('data-params', params);
    }, this);

    if (this.selected.size > 0) {
      document.querySelectorAll('.bulk-actions').forEach((element) => {
        element.classList.add('show');
        element.classList.remove('hide');
      });
    } else {
      document.querySelectorAll('.bulk-actions').forEach((element) => {
        element.classList.add('hide');
        element.classList.remove('show');
      });
    }
  }

  _allSelected() {
    return this.selected.size === this.rowTargets.length;
  }

  _noneSelected() {
    return this.selected.size === 0;
  }
}
