import { Controller } from '@hotwired/stimulus';

const toSnakeCase = (str) => {
  return (
    str.slice(0, 1).toLowerCase() +
    str
      .split('')
      .slice(1)
      .map((char) => {
        if (char === char.toUpperCase()) return `_${char.toLowerCase()}`;
        return char;
      })
      .join('')
  );
};

export default class extends Controller {
  static targets = ['source', 'target'];

  targetTargetConnected(target) {
    setTimeout(() => this.handleSelectChange(), 500);
  }

  handleSelectChange() {
    Array.from(this.sourceTargets).forEach((s) => {
      const selectedObject = $(s).select2('data')[0];

      if (selectedObject.id === '') return;

      Array.from(this.targetTargets).forEach((t) => {
        if (s === t) return;

        const urlParams = JSON.parse(t.dataset.ajaxSourceUrlParams);

        Object.entries(selectedObject).forEach((entry) => {
          const key = entry[0];

          if (toSnakeCase(key) in urlParams) {
            urlParams[toSnakeCase(key)] = selectedObject[key];
          }
        });

        t.dataset.ajaxSourceUrlParams = JSON.stringify(urlParams);
      });
    });
  }
}
