import { Controller } from '@hotwired/stimulus';
import { chunk, sum, sumBy } from 'lodash';

class DeliveryPlannerController extends Controller {
  static targets = [
    'regionQuantity',
    'regionQuantitySelected',
    'productQuantity',
    'productQuantitySelected',
    'totalQuantity',
    'totalQuantitySelected',
    'region',
    'product',
    'updateBtn',
    'createBtn',
  ];

  initialize() {
    const rowLength = this.element.querySelectorAll(
      'table.delivery-planner-table > thead > tr:first-child > th',
    ).length;

    this.allCells = this.element.querySelectorAll(`
        table.delivery-planner-table > thead > tr > th,
        table.delivery-planner-table > thead > tr > td,
        table.delivery-planner-table > tbody > tr > td,
        table.delivery-planner-table > tbody > tr > th,
        table.delivery-planner-table > tfoot > tr > td,
        table.delivery-planner-table > tfoot > tr > th
      `);

    this.cellsByRow = chunk(this.allCells, rowLength);
    this.cellsByColumn = [...Array(rowLength).keys()].map((i) => this.cellsByRow.map((r) => r[i]));

    $(document).on('click', '.delivery-planner .dropdown-menu', (e) => e.stopPropagation());
  }

  filterMatrix(event) {
    event.preventDefault();
    event.currentTarget.classList.toggle('active');

    this._filterMatrix();
    this._updateQuantities();
  }

  _filterMatrix() {
    const regionTargetMap = this.regionTargets.reduce(
      (map, regionTarget) => ({
        ...map,
        [regionTarget.dataset.region]: regionTarget.classList.contains('active'),
      }),
      {},
    );

    const productTargetMap = this.productTargets.reduce(
      (map, productTarget) => ({
        ...map,
        [productTarget.dataset.product]: productTarget.classList.contains('active'),
      }),
      {},
    );

    this.allCells.forEach((cell) => {
      const { region } = cell.dataset;
      const { product } = cell.dataset;

      if (!region && !product) return;

      const regionSelected = region && regionTargetMap[region];
      const productSelected = product && productTargetMap[product];

      if (region && !product) {
        cell.classList.toggle('d-none', !regionSelected);
      } else if (!region && product) {
        cell.classList.toggle('d-none', !productSelected);
      } else {
        cell.classList.toggle('d-none', !(regionSelected && productSelected));
      }
    }, this);

    // Hide empty rows
    this.cellsByRow.forEach((row) => {
      const { region } = row[0].dataset;
      if (!region || !DeliveryPlannerController.allCellsAreHiddenOrEmpty(row)) return;

      const option = this.regionTargets.find((t) => t.dataset.region === region);

      if (option.classList.contains('active')) {
        option.classList.remove('active');
        this._filterMatrix();
      }
    }, this);

    // Hide empty columns
    this.cellsByColumn.forEach((column) => {
      const { product } = column[0].dataset;
      if (!product || !DeliveryPlannerController.allCellsAreHiddenOrEmpty(column)) return;

      const option = this.productTargets.find((t) => t.dataset.product === product);

      if (option.classList.contains('active')) {
        option.classList.remove('active');
        this._filterMatrix();
      }
    }, this);
  }

  toggleCell(event) {
    if (event.target !== event.currentTarget && event.target.parentNode !== event.currentTarget)
      return;

    const t = event.currentTarget;
    const cell = t.nodeName === 'INPUT' ? t.closest('td') : t;
    const checkboxes = Array.from(cell.querySelectorAll('input'));
    const allSelected = checkboxes
      .filter((c) => !c.id.startsWith('checkbox-'))
      .every((c) => c.checked);

    if (allSelected) {
      DeliveryPlannerController.unselectCell(cell);
    } else {
      DeliveryPlannerController.selectCell(cell);
    }

    this._toggleButtons();
    this._toggleHeaderHighlights();
    this._updateQuantities();
  }

  toggleLine(event) {
    const checkbox = event.currentTarget;
    const cell = checkbox.closest('td').parentElement.closest('td');
    const checkboxes = Array.from(checkbox.closest('table').querySelectorAll('input')).filter(
      (c) => !c.id.startsWith('checkbox-'),
    );
    const allSelected = checkboxes.every((c) => c.checked);
    const noneSelected = !checkboxes.some((c) => c.checked);
    const allCheckbox = checkbox.closest('table').querySelector('thead input');

    if (allSelected) {
      DeliveryPlannerController.selectCheckbox(allCheckbox);
      DeliveryPlannerController.selectCell(cell);
    } else if (noneSelected) {
      DeliveryPlannerController.unselectCheckbox(allCheckbox);
      DeliveryPlannerController.unselectCell(cell);
    } else {
      DeliveryPlannerController.indeterminateCheckbox(allCheckbox);
      DeliveryPlannerController.indeterminateCell(cell);
    }

    this._toggleButtons();
    this._toggleHeaderHighlights();
    this._updateQuantities();
  }

  toggleRow(event) {
    const cellRow = event.currentTarget.closest('tr');
    const allRows = cellRow.closest('tbody').children;
    const rowIdx = Array.prototype.slice.call(allRows).indexOf(cellRow) + 1;

    const rowCells = this.cellsByRow[rowIdx];
    const action = DeliveryPlannerController.isAllChecked(rowCells)
      ? DeliveryPlannerController.unselectCell
      : DeliveryPlannerController.selectCell;

    rowCells.forEach(action, DeliveryPlannerController);

    this._toggleButtons();
    this._toggleHeaderHighlights();
    this._updateQuantities();
  }

  toggleColumn(event) {
    const cell = event.currentTarget;
    const cellSiblings = cell.closest('tr').children;
    const columnIdx = Array.prototype.slice.call(cellSiblings).indexOf(cell);

    const columnCells = this.cellsByColumn[columnIdx];
    const action = DeliveryPlannerController.isAllChecked(columnCells)
      ? DeliveryPlannerController.unselectCell
      : DeliveryPlannerController.selectCell;

    columnCells.forEach(action, DeliveryPlannerController);

    this._toggleButtons();
    this._toggleHeaderHighlights();
    this._updateQuantities();
  }

  // eslint-disable-next-line class-methods-use-this
  openModal(event) {
    event.preventDefault();
    event.stopPropagation();

    const cell = event.currentTarget;
    $(cell).find('.modal').modal('show');
  }

  _toggleButtons() {
    const anyChecked = Array.from(this.allCells).some(
      (c) => c.querySelectorAll('input:checked').length > 0,
    );

    this.updateBtnTarget.disabled = !anyChecked;
    this.createBtnTarget.disabled = !anyChecked;
  }

  _toggleHeaderHighlights() {
    [...this.cellsByRow, ...this.cellsByColumn].forEach((cells) => {
      const anyChecked = cells.some((c) => c.querySelectorAll('input:checked').length > 0);
      const firstCell = cells[0];
      const lastCell = cells[cells.length - 1];

      firstCell.classList.toggle('bg-white', !anyChecked);
      firstCell.classList.toggle('bg-primary', anyChecked);
      firstCell.classList.toggle('text-white', anyChecked);
      lastCell.classList.toggle('bg-dark', !anyChecked);
      lastCell.classList.toggle('bg-primary', anyChecked);
    });
  }

  _updateQuantities() {
    const rows = this.cellsByRow
      .map((row) => row.filter((cell) => cell.nodeName === 'TD'))
      .filter((row) => row.length !== 0);
    const columns = this.cellsByColumn
      .map((column) => column.filter((cell) => cell.nodeName === 'TD'))
      .filter((column) => column.length !== 0)
      .slice(1, -1);

    const getQuantityFromCell = (dataKey) => (cell) =>
      !cell.classList.contains('d-none') && cell.dataset[dataKey]
        ? parseInt(cell.dataset[dataKey], 10)
        : 0;

    const regionQuantities = rows.map((row) => sumBy(row, getQuantityFromCell('quantity')));
    const productQuantities = columns.map((column) =>
      sumBy(column, getQuantityFromCell('quantity')),
    );
    const totalQuantity = sum(productQuantities);
    const selectedRegionQuantities = rows.map((row) =>
      sumBy(row, getQuantityFromCell('selectedQuantity')),
    );
    const selectedProductQuantities = columns.map((column) =>
      sumBy(column, getQuantityFromCell('selectedQuantity')),
    );
    const selectedTotalQuantity = sum(selectedProductQuantities);

    /* eslint-disable no-return-assign, no-param-reassign */
    this.productQuantityTargets.forEach((t, idx) => (t.textContent = productQuantities[idx]));
    this.regionQuantityTargets.forEach((t, idx) => (t.textContent = regionQuantities[idx]));
    this.productQuantitySelectedTargets.forEach(
      (t, idx) => (t.textContent = selectedProductQuantities[idx]),
    );
    this.regionQuantitySelectedTargets.forEach(
      (t, idx) => (t.textContent = selectedRegionQuantities[idx]),
    );
    /* eslint-enable no-return-assign, no-param-reassign */

    this.totalQuantityTarget.textContent = totalQuantity;
    this.totalQuantitySelectedTarget.textContent = selectedTotalQuantity;
  }

  static isAllChecked(cells) {
    return cells.every(
      (cell) =>
        cell.querySelectorAll('input').length === 0 ||
        cell.classList.contains('d-none') ||
        cell.querySelectorAll('input:checked').length > 0,
    );
  }

  static selectCell(cell) {
    const selectableCell =
      !cell.classList.contains('d-none') && !!cell.querySelector('.selected-quantity');

    if (selectableCell) {
      const checkboxes = Array.from(cell.querySelectorAll('input'));
      checkboxes.forEach(this.selectCheckbox);

      this.setSelectedQuantity(cell);
      cell.classList.remove('indeterminate');
      cell.classList.add('selected');
    }
  }

  static unselectCell(cell) {
    const selectableCell =
      !cell.classList.contains('d-none') && !!cell.querySelector('.selected-quantity');

    if (selectableCell) {
      const checkboxes = Array.from(cell.querySelectorAll('input'));

      checkboxes.forEach(this.unselectCheckbox);

      this.setSelectedQuantity(cell);

      cell.classList.remove('indeterminate');
      cell.classList.remove('selected');
    }
  }

  static indeterminateCell(cell) {
    this.setSelectedQuantity(cell);

    cell.classList.add('indeterminate');
    cell.classList.remove('selected');
  }

  static getSelectedQuantity(cell) {
    const checkboxes = Array.from(cell.querySelectorAll('input'));

    return checkboxes.reduce(
      (quantity, checkbox) =>
        checkbox.dataset.quantity && checkbox.checked
          ? quantity + parseInt(checkbox.dataset.quantity, 10)
          : quantity,
      0,
    );
  }

  /* eslint-disable no-param-reassign */
  static setSelectedQuantity(cell) {
    const selectedQuantity = this.getSelectedQuantity(cell);
    cell.dataset.selectedQuantity = selectedQuantity;
    cell.querySelector('.selected-quantity').textContent = selectedQuantity;
  }
  /* eslint-enable no-param-reassign */

  /* eslint-disable no-param-reassign */
  static selectCheckbox(checkbox) {
    checkbox.checked = true;
    checkbox.indeterminate = false;
  }

  static unselectCheckbox(checkbox) {
    checkbox.checked = false;
    checkbox.indeterminate = false;
  }

  static indeterminateCheckbox(checkbox) {
    checkbox.checked = false;
    checkbox.indeterminate = true;
  }
  /* eslint-enable no-param-reassign */

  static allCellsAreHiddenOrEmpty(cells) {
    return cells.every(
      (cell) =>
        cell.classList.contains('d-none') ||
        cell.dataset.quantity === '0' ||
        !cell.dataset.quantity,
    );
  }
}

export default DeliveryPlannerController;
