import { Controller } from '@hotwired/stimulus';

class CampaignDeliveryMethodsController extends Controller {
  static values = { selectedRegionIds: Array };

  static targets = ['deliveryMethod', 'regionsSelect', 'container'];

  connect() {
    const regionIds = this.selectedRegionIdsValue;

    this.deliveryMethodTargets.forEach((deliveryMethodRow) => {
      const regionId = parseInt(deliveryMethodRow.dataset.regionId, 10);
      const checkbox = deliveryMethodRow.querySelector("input[type='checkbox']");

      if (regionIds.includes(regionId)) {
        deliveryMethodRow.classList.remove('d-none');

        if (checkbox.checked) {
          CampaignDeliveryMethodsController.selectDeliveryMethodRow(deliveryMethodRow);
        } else {
          CampaignDeliveryMethodsController.unselectDeliveryMethodRow(deliveryMethodRow);
        }
      } else {
        deliveryMethodRow.classList.add('d-none');
      }
    });

    this.containerTargets.forEach((container) => {
      if (container.querySelectorAll('.form-row:not(.d-none)').length > 0) {
        container.classList.remove('d-none');
      }
    });
  }

  handleRegionSelect(event) {
    const select = event.target;
    const regionIds = Array.from(select.selectedOptions).map((o) => parseInt(o.value, 10));

    this.deliveryMethodTargets.forEach((deliveryMethodRow) => {
      const regionId = parseInt(deliveryMethodRow.dataset.regionId, 10);
      const checkbox = deliveryMethodRow.querySelector("input[type='checkbox']");

      if (regionIds.includes(regionId)) {
        const isNewRegion = !this.selectedRegionIdsValue.includes(regionId);
        const isGeneric = deliveryMethodRow.dataset.generic === 'true';
        const isHomeDelivery = deliveryMethodRow.dataset.generic === undefined;

        deliveryMethodRow.classList.remove('d-none');

        if (isNewRegion && (isGeneric || isHomeDelivery)) {
          checkbox.checked = true;
        }

        if (checkbox.checked) {
          CampaignDeliveryMethodsController.selectDeliveryMethodRow(deliveryMethodRow);
        } else {
          CampaignDeliveryMethodsController.unselectDeliveryMethodRow(deliveryMethodRow);
        }
      } else {
        deliveryMethodRow.classList.add('d-none');
        CampaignDeliveryMethodsController.unselectDeliveryMethodRow(deliveryMethodRow);
        checkbox.checked = false;
      }
    });

    this.selectedRegionIdsValue = regionIds;
    this.sortDeliveryMethods();

    this.containerTargets.forEach((container) => {
      if (container.querySelectorAll('.form-row:not(.d-none)').length > 0) {
        container.classList.remove('d-none');
      } else {
        container.classList.add('d-none');
      }
    });
  }

  handleDeliveryMethodSelect(event) {
    const checkbox = event.target;
    const deliveryMethodRow = checkbox.closest('.form-row');

    if (checkbox.checked) {
      CampaignDeliveryMethodsController.selectDeliveryMethodRow(deliveryMethodRow);
    } else {
      CampaignDeliveryMethodsController.unselectDeliveryMethodRow(deliveryMethodRow);
    }

    this.sortDeliveryMethods();
  }

  sortDeliveryMethods() {
    const groupedDeliveryMethods = Object.values(
      Array.from(this.deliveryMethodTargets).reduce((groups, t) => {
        if (!t.classList.contains('d-none')) {
          groups[t.parentNode] ||= [];
          groups[t.parentNode].push(t);
        }

        return groups;
      }, {}),
    );

    groupedDeliveryMethods.forEach((deliveryMethods) => {
      deliveryMethods
        .sort((a, b) => {
          const checkboxA = a.querySelector("input[type='checkbox']");
          const checkboxB = b.querySelector("input[type='checkbox']");

          if (checkboxA.checked === checkboxB.checked) {
            const regionA = a.dataset.regionName;
            const regionB = b.dataset.regionName;
            const regionNameComparison = regionA.toLowerCase().localeCompare(regionB.toLowerCase());

            if (regionNameComparison === 0) {
              const startTimeA =
                a.querySelector("input[type='datetime-local']").value === ''
                  ? 0
                  : a.querySelector("input[type='datetime-local']").value;

              const startTimeB =
                b.querySelector("input[type='datetime-local']").value === ''
                  ? 0
                  : b.querySelector("input[type='datetime-local']").value;

              const startTimeComparison = Date.parse(startTimeA) - Date.parse(startTimeB);

              if (startTimeComparison === 0) {
                const endTimeA =
                  a.querySelectorAll("input[type='datetime-local']")[1].value === ''
                    ? 0
                    : a.querySelectorAll("input[type='datetime-local']")[1].value;

                const endTimeB =
                  b.querySelectorAll("input[type='datetime-local']")[1].value === ''
                    ? 0
                    : b.querySelectorAll("input[type='datetime-local']")[1].value;

                const endTimeComparison = Date.parse(endTimeA) - Date.parse(endTimeB);

                if (endTimeComparison === 0) {
                  const nameA = a.dataset.name;
                  const nameB = b.dataset.name;
                  const nameComparison = nameA.toLowerCase().localeCompare(nameB.toLowerCase());

                  return nameComparison;
                }
                return endTimeComparison;
              }
              return startTimeComparison;
            }
            return regionNameComparison;
          }

          if (checkboxA.checked) return -1;
          return 1;
        })
        .forEach((dm) => {
          dm.parentNode.appendChild(dm);
        });
    });
  }

  static selectDeliveryMethodRow(deliveryMethodRow) {
    const label = deliveryMethodRow.querySelector('label');

    deliveryMethodRow.classList.remove('bg-light');
    label.classList.remove('text-muted');
  }

  static unselectDeliveryMethodRow(deliveryMethodRow) {
    const label = deliveryMethodRow.querySelector('label');

    deliveryMethodRow.classList.add('bg-light');
    label.classList.add('text-muted');
  }
}

export default CampaignDeliveryMethodsController;
