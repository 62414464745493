/* eslint no-console:0 */
import '../stylesheets/internal.scss';

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';

import 'jquery';
import LocalTime from 'local-time';
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import 'trix';
import '@rails/actiontext';
import '@rails/activestorage';
import 'bootstrap';
import '@nathanvda/cocoon';
import 'popper.js';
import 'select2';
import Mustache from 'mustache';

import './internal/datatables';

require.context('../images', true);

window.jQuery = jQuery;
window.$ = $;

Rails.start();
Turbolinks.start();
LocalTime.start();

const application = Application.start();
const context = require.context('./internal/controllers', true, /\.js$/);
application.load(definitionsFromContext(context));

const truncateString = (string = '', maxLength = 50) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

const App = {
  isWithTooltips: false,

  init() {
    App.dataTables();
    App.tooltips();
    App.formValidation();
    App.cocoonSetup();
    App.select2();
    App.goToTab();

    $(document).on('cocoon:after-insert', App.select2);
    $(document).on('modal:shown', App.select2);
    $(document).on('modal:shown', App.formValidation);
    $(window).on('resize', App.tooltips);
    $(document).on('render-async:done', App.dataTables);
  },

  goToTab() {
    const url = document.location.toString();
    if (url.match('#')) {
      $(`.nav-tabs a[href="#${url.split('#')[1]}"]`).tab('show');
    }

    // Change hash for page-reload
    $('.nav-tabs a').on('shown.bs.tab', (e) => {
      const scrollmem = $('body').scrollTop() || $('html').scrollTop();
      window.location.hash = e.target.hash;
      $('html,body').scrollTop(scrollmem);
    });
  },

  select2() {
    const $selects = $('.js-select');

    $selects.each((_, element) => {
      const $element = $(element);

      if ($element.hasClass('select2-hidden-accessible')) {
        return;
      }

      const ajaxUrl = $element.data('ajax-source-url') || null;
      const dynamicOptions = $element.data('dynamic-options') || false;
      const idKey = $element.data('id-key') || 'id';
      const textKey = $element.data('text-key') || 'displayName';
      const template = $element.data('template') || null;
      const truncate = $element.data('truncate') || null;
      const allowClear = $element.data('allow-clear') || false;
      const placeholder = $element.data('placeholder') || null;

      let options = {
        theme: 'bootstrap',
        width: 'style',
        templateResult(item, container) {
          const option = $(item.element);

          container.classList.add('d-flex');
          container.classList.add('align-items-center');

          if (item.inactive) {
            container.classList.add('text-muted');
            container.classList.add('bg-light');
          }

          if (template) {
            return $(Mustache.render(template, item));
          }

          if (option.data('template')) {
            return $(option.data('template'));
          }

          return item.text;
        },
        templateSelection(item, container) {
          const option = $(item.element);

          const group = option.closest('optgroup');

          if (group.length > 0) {
            return `${group.attr('label')} | ${item.text}`;
          }

          if (truncate) {
            return truncateString(item.text, container.width() / 10);
          }

          return item.text;
        },
      };

      if (allowClear) {
        options = Object.assign(options, {
          allowClear: true,
          placeholder,
        });
      }

      if (ajaxUrl) {
        options = Object.assign(options, {
          ajax: {
            url() {
              const url = `${this[0].dataset.ajaxSourceUrl}.json`;
              const params = new URLSearchParams(
                Object.entries(JSON.parse(this[0].dataset.ajaxSourceUrlParams || '{}')).reduce(
                  (a, [k, v]) => (v === null ? a : ((a[k] = v), a)),
                  {},
                ),
              );

              return `${url}?${params}`;
            },
            dataType: 'json',
            data(params) {
              const query = {
                filter: params.term,
                page: params.page || 1,
              };

              return query;
            },
            processResults(response) {
              const results = {
                results: $.map(response.data, (r) => {
                  if (!r[idKey] && !r[idKey] === 0) {
                    console.error(`Object does not contain non-null key \`${idKey}\``, r);
                  }

                  if (!r[textKey]) {
                    console.error(`Object does not contain non-null key \`${textKey}\``, r);
                  }

                  return {
                    id: r[idKey],
                    text: r[textKey],
                    ...r,
                  };
                }),
                pagination: {
                  more:
                    response.links.next !== response.links.self &&
                    response.links.next !== response.links.prev,
                },
              };

              return results;
            },
          },
        });
      }

      if (dynamicOptions) {
        options = Object.assign(options, { tags: true });
      }

      $element.select2(options);

      $element.on('select2:select', (event) => {
        event.currentTarget.dispatchEvent(new Event('change', { bubbles: true }));
      });

      $element.on('select2:unselect', (event) => {
        event.currentTarget.dispatchEvent(new Event('change', { bubbles: true }));
      });

      $element.on('select2:unselecting', () => {
        $element.on('select2:opening', (e) => {
          e.preventDefault();
          $element.off('select2:opening');
        });
      });
    });
  },

  cocoonSetup() {
    $(document).on('cocoon:before-remove', (event, record) => {
      if (record.siblings('.nested-fields').length === 0) {
        event.preventDefault();
      }
    });

    $(document).on('cocoon:after-remove', () => {
      if ($('form .nested-fields').length === 1) {
        $('form .nested-fields .remove_fields').addClass('disabled', true);
      }
    });

    $(document).on('cocoon:after-insert', () => {
      if ($('form .nested-fields').length > 1) {
        $('form .nested-fields .remove_fields').removeClass('disabled', true);
      }
    });

    if ($('form .nested-fields').length === 1) {
      $('form .nested-fields .remove_fields').addClass('disabled', true);
    }
  },

  formValidation() {
    const $form = $('form.needs-validation');

    $form.on('submit', (event) => {
      if (!event.currentTarget.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }

      $(event.currentTarget).addClass('was-validated');
      $(event.currentTarget).trigger('form:validated');
    });
  },

  tooltips() {
    $('[data-toggle="tooltip"]').tooltip();
    if ($(window).width() > 768) {
      if (App.isWithTooltips) return;
      App.isWithTooltips = true;
    } else {
      if (!App.isWithTooltips) return;
      App.isWithTooltips = false;
      $('[data-toggle="tooltip"]').tooltip('dispose');
    }
  },

  dataTables() {
    $('table.datatable').defaultDataTable();
  },
};

const Customers = {
  init() {
    Customers.adjustTimelineHeight();
  },

  adjustTimelineHeight() {
    const adjustHeight = () => {
      const $customerDetails = $('.customer-details');
      const $commentForm = $('.comment-form');
      const $commentsList = $('.comments-list');

      const commentFormHeight = $commentForm.outerHeight(true);
      const customerDetailsHeight = $customerDetails.outerHeight();

      const diff = -commentFormHeight + customerDetailsHeight;

      if (diff < $commentsList.outerHeight()) {
        $commentsList.animate(
          {
            'max-height': `${-commentFormHeight + customerDetailsHeight}px`,
          },
          200,
        );

        $commentsList.addClass('overflow');
      } else {
        $commentsList.removeClass('overflow');
      }
    };

    $('.customer-details').on('shown.bs.collapse hidden.bs.collapse', () => {
      adjustHeight();
    });

    adjustHeight();
  },
};

$(document).ready(() => {
  App.init();

  if ($('body').hasClass('customers')) {
    Customers.init();
  }
});
