/* eslint-disable max-classes-per-file */

import Dropzone from 'dropzone';
import { Controller } from '@hotwired/stimulus';
import { DirectUpload } from '@rails/activestorage';

function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

function createDropZone(controller) {
  const dropzone = new Dropzone(controller.element, {
    url: controller.url,
    headers: {
      'X-CSRF-Token': document.head
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content'),
    },
    maxFiles: controller.maxFiles - controller.existingImages.length,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    uploadMultiple: controller.uploadMultiple,
    thumbnailWidth: 167,
    thumbnailHeight: 108,
    autoQueue: false,
  });

  dropzone.on('maxfilesexceeded', (file) => {
    dropzone.removeFile(file);
  });

  controller.existingImages.forEach((file, idx) => {
    const mockFile = {
      name: `Image ${idx}`,
      size: 0,
    };

    dropzone.files.push(mockFile);
    dropzone.displayExistingFile(mockFile, file, null, 'Anonymous');
  });

  return dropzone;
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener('progress', (event) => this.uploadRequestDidProgress(event));
  }

  uploadRequestDidProgress(event) {
    const progress = (event.loaded / event.total) * 100;
    const progressBar = this.file.previewTemplate.querySelector('.dz-upload');
    progressBar.style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit('processing', this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit('error', this.file, error);
    this.source.dropZone.emit('complete', this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit('success', this.file);
    this.source.dropZone.emit('complete', this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = 'none';
  }

  bindEvents() {
    this.dropZone.on('addedfile', (file) => {
      setTimeout(() => {
        if (file.accepted) createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on('removedfile', (file) => {
      if (file.controller) {
        removeElement(file.controller.hiddenInput);
      } else {
        const signedId = file.dataURL
          .replace('/rails/active_storage/blobs/', '')
          .replace(/\/.*\.\w+$/, '');

        const hiddenInput = this.element.querySelector(`input[type='hidden'][value='${signedId}']`);
        removeElement(hiddenInput);
      }
    });

    this.dropZone.on('canceled', (file) => {
      if (file.controller) file.controller.xhr.abort();
    });
  }

  get url() {
    return this.inputTarget.getAttribute('data-direct-upload-url');
  }

  get uploadMultiple() {
    return this.data.get('multiple') || false;
  }

  get maxFiles() {
    return this.data.get('maxFiles') || 1;
  }

  get maxFileSize() {
    return this.data.get('maxFileSize') || 256;
  }

  get acceptedFiles() {
    return this.data.get('acceptedFiles');
  }

  get addRemoveLinks() {
    return this.data.get('addRemoveLinks') || true;
  }

  get existingImages() {
    if (this.data.get('existingImages').length > 0) {
      return this.data.get('existingImages').split(',');
    }
    return [];
  }
}
