import { Controller } from '@hotwired/stimulus';

class ConversationsController extends Controller {
  static targets = ['handleCheckbox', 'conversationListItem'];

  connect() {
    this.handleCheckboxTargets.forEach((checkbox) => {
      $(checkbox).on('change', (evt) => {
        $(evt.currentTarget.form).submit();
      });
    });
  }

  showTab(event) {
    Array.from(event.currentTarget.parentElement.children).forEach((listItem) => {
      listItem.classList.remove('active');
    });

    $(event.currentTarget).tab('show');
  }
}

export default ConversationsController;
