import { Controller } from '@hotwired/stimulus';

class SendOutFilterController extends Controller {
  static targets = ['filterTypeSelect', 'keySelect', 'valueSelect'];

  initialize() {
    if (this.keySelectTarget.value !== '') {
      const selectedOption = this.keySelectTarget.options[this.keySelectTarget.selectedIndex];
      const { ajaxSourceUrl } = selectedOption.dataset;
      this.valueSelectTarget.dataset.ajaxSourceUrl = ajaxSourceUrl;
    }
  }

  connect() {
    this.element[this.identifier] = this;
  }

  selectKey(event) {
    const element = event.currentTarget;
    const selectedOption = element.options[element.selectedIndex];
    const { ajaxSourceUrl } = selectedOption.dataset;

    this.valueSelectTarget.dataset.ajaxSourceUrl = ajaxSourceUrl;
  }

  get filter() {
    return {
      filterType: this.filterTypeSelectTargets.filter((radio) => radio.checked)[0].value,
      key: this.keySelectTarget.value,
      values: Array.from(this.valueSelectTarget.selectedOptions).map((option) => option.value),
    };
  }
}

export default SendOutFilterController;
